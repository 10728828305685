import React from 'react';
import GatsbyLink from 'gatsby-link';
import styled from 'react-emotion';
import { H6Text } from '../components/Typography';
import { colors } from '../components/styles';
import { Content } from './Container';
import Item from '../components/grid/Item';

import cogoLogoPath from '../images/cogo_logo.svg';
import twitterLogoPath from '../images/twitter.svg';
import facebookLogoPath from '../images/facebook.svg';
import linkedinLogoPath from '../images/linkedin.svg';
import slackLogoPath from '../images/slack.svg';

const Logo = styled.img`
  padding-top: 10px;
  height: 60px;
  width: 80px;
`;

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: none;
`;

const Li = styled.li`
  margin: 0;
  padding: 0;
`;

const Link = styled(GatsbyLink)`
  text-decoration: none;
  color: ${colors.blue};
  line-height: 150%;
  font-size: 1em;
  &:hover {
    color: ${colors.green};
  }
`;

const A = Link.withComponent('a');

const StrongLink = styled(Link)`
  font-weight: 600;
`;

const FooterColumn = styled(Item)`
  width: 100%;
  display: inline-block;
  float: left;

  @media (min-width: 320px) {
    width: 50%;
    margin-top: 20px;
    margin-bottom: 0;
    min-height: 12em;
  }

  @media (min-width: 500px) {
    width: 33.333%;
    min-height: 15em;
  }

  @media (min-width: 690px) {
    width: 33.333%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media (min-width: 810px) {
    width: 33.333%;
  }

  @media (min-width: 970px) {
    width: 16.666%;
  }

  @media (min-width: 1140px) {
    width: 16.666%;
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

const SocialIcon = styled.img`
  width: 100%;
  max-width: 24px;
  margin-right: 20px;
  float: left;

  @media (min-width: 320px) {
    margin-left: 10px;
    float: left;
  }

  @media (min-width: 690px) {
    float: right;
  }

  @media (min-width: 810px) {
    max-width: 18px;
  }
`;

const FooterBaseCol = styled(Item)`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
  float: left;

  @media (min-width: 320px) {
    width: 100%;
  }

  @media (min-width: 690px) {
    width: 50%;
  }
`;

const MadeIn = styled.small`
  color: ${colors.concrete};
`;

const Footer = ({ backgroundColor = colors.silver }) => (
  <footer>
    <Content backgroundColor={backgroundColor}>
      <FooterColumn>
        <Link to="/">
          <Logo src={cogoLogoPath} alt="Cogo Footer Logo" />
        </Link>
      </FooterColumn>
      <FooterColumn>
        <H6Text color={colors.gray} align="left">
          General
        </H6Text>
        <Ul>
          <Li>
            <Link to="/">Home</Link>
          </Li>
          <Li>
            <Link to="/partners">Partners</Link>
          </Li>
        </Ul>
      </FooterColumn>
      <FooterColumn>
        <H6Text color={colors.gray} align="left">
          Resources
        </H6Text>
        <Ul>
          <Li>
            <Link to="/faq">FAQ</Link>
          </Li>
          {/* <Li>
          <Link to="/developers">Developers</Link>
        </Li>
        <Li>
          <Link to="/feature-requests">Feature Requests</Link>
        </Li> */}
          <Li>
            <Link to="/api">Cogo API</Link>
          </Li>
          <Li>
            <A href="mailto:help@cogo.ai">Help and Support</A>
          </Li>
        </Ul>
      </FooterColumn>
      <FooterColumn>
        <H6Text color={colors.gray} align="left">
          About Us
        </H6Text>
        <Ul>
          <Li>
            <Link to="/company">Company</Link>
          </Li>
          <Li>
            <Link to="/jobs">Jobs</Link>
          </Li>
          {/* <Li>
          <Link to="/blog">Blog</Link>
        </Li> */}
          <Li>
            <Link to="/press">Press</Link>
          </Li>
          <Li>
            <A href="mailto:hello@cogo.ai">Contact</A>
          </Li>
        </Ul>
      </FooterColumn>
      <FooterColumn>
        <H6Text color={colors.gray} align="left">
          Legal
        </H6Text>
        <Ul>
          <Li>
            <Link to="/terms-of-service">Terms of Service</Link>
          </Li>
          <Li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </Li>
          <Li>
            <Link to="/security">Security</Link>
          </Li>
        </Ul>
      </FooterColumn>

      <FooterBaseCol>
        <MadeIn>© 2017 Cogo. Made in Singapore. 🇸🇬</MadeIn>
      </FooterBaseCol>

      <FooterBaseCol>
        <a href="http://www.twitter.com/cogoai">
          <SocialIcon src={twitterLogoPath} />
        </a>
        <a href="https://www.facebook.com/cogoai/">
          <SocialIcon src={facebookLogoPath} />
        </a>
        <a href="https://www.linkedin.com/company/cogoai">
          <SocialIcon src={linkedinLogoPath} />
        </a>
        <a href="/slack">
          <SocialIcon src={slackLogoPath} />
        </a>
      </FooterBaseCol>
    </Content>
  </footer>
);

export default Footer;
