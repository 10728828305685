import React from 'react';
import styled from 'react-emotion';
import { GridLeftTopCol, GridTopCol } from '../../components/grid/Item';

const WithPaddingLeft = styled(GridLeftTopCol)`
  text-align: left;
`;

const WithPaddingTop = styled(GridTopCol)`
  text-align: center;
`;

export default ({ isLeft, ...props }) =>
  isLeft ? <WithPaddingLeft {...props} /> : <WithPaddingTop {...props} />;
