import React from 'react';
import styled from 'react-emotion';
import CappedWidth from '../../components/CappedWidth';

const GradientFullWrapper = styled.div`
  width: 100%;
  float: left;
  margin: 0 auto;
`;

const HeroContent = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 80vh;
`;

export default ({ children }) => (
  <GradientFullWrapper>
    <CappedWidth>
      <HeroContent>{children}</HeroContent>
    </CappedWidth>
  </GradientFullWrapper>
);
