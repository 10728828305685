import React from 'react';
import styled from 'react-emotion';
import cogoDownArrowWhitePath from '../images/cogo_down_arrow_white.svg';

const ArrowImg = styled.img`
  max-width: 2em;
  width: 100%;
  margin-bottom: 40px;
  padding-top: 0px;
`;

const ArrowDown = () => (
  <div>
    <ArrowImg src={cogoDownArrowWhitePath} alt="White Arrow Down" />
  </div>
);

export default ArrowDown;
