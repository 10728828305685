import React from 'react';
import styled, { css } from 'react-emotion';
import { colors } from '../components/styles';

const EmailInput = styled.input`
  width: 100%;
  @media (min-width: 320px) {
    width: 50%;
    font-size: 1em;
    color: ${colors.gray};
    appearance: none;
    box-shadow: none;
    border-radius: 5px 0px 0px 5px;
    border: 1px solid ${colors.chrome};
    padding: 10px;
  }

  @media (min-width: 500px) {
    width: 50%;
    border-radius: 5px 0px 0px 5px;
  }

  @media (min-width: 690px) {
    width: 33.3333%;
    border-radius: 5px 0px 0px 5px;
  }
`;

const SubmitButton = styled.input`
  max-width: 250px;
  text-decoration: none;

  width: auto;
  font-size: 1em;
  letter-spacing: 0.02em;
  background: ${colors.red};
  appearance: none;
  box-shadow: none;
  border-radius: 0px 5px 5px 0px;
  border: 1px solid ${colors.red};
  padding: 10px;
  color: ${colors.white};
  margin-top: 10px;
  transition-duration: 0.2s;
  margin-bottom: 40px;
  &:hover {
    background: ${colors.richRed};
  }
`;

export const SignUpForm = styled.form`
  margin-top: 50px;
  margin-bottom: 50px;
`;

// const EmailForm = ({ children }) => (
//   <SignUpForm action="https://app.cogo.ai/onboarding" method="post">
//     <EmailInput type="text" name="email_address" placeholder="Email" />
//     <SubmitButton type="submit" value="Get started" />
//     {children}
//   </SignUpForm>
// );

const EmailForm = ({ children, align }) => (
  <SignUpForm
    align={align}
    action="https://cogoplatform.us2.list-manage.com/subscribe/post?u=ef9d28f9108728dac5b5ce200&amp;id=67f6c8d540"
    method="post"
    target="_blank"
    novalidate
  >
    <EmailInput type="email" name="EMAIL" placeholder="Email" />
    <SubmitButton type="submit" value="Request invite" />
    {children}
  </SignUpForm>
);

export default EmailForm;
