import React from 'react';
import Header from '../components/Header/Header';
import HeaderWrapper from '../components/Header/HeaderWrapper';
import ArrowDown from '../components/ArrowDown';
import { H1, H4Text, Blockquote, Paragraph } from '../components/Typography';
import EmailForm from '../components/EmailForm';
import { colors } from '../components/styles';
import Particles, { Overlay } from '../components/Particles';
import HeaderFullWrapper from '../components/Header/HeaderFullWrapper';
import OutlineButton from '../components/OutlineButton';

export default ({
  subtitle,
  preTitle = null,
  title,
  children,
  noParticles = false,
  isFull = false,
  cta = false,
  isEnterprise = false,
  enterpriseCta = false,
}) => {
  const ContentWrapper = isFull ? HeaderFullWrapper : 'div';
  return (
    <Header isFull={isFull} isEnterprise={isEnterprise}>
      <ContentWrapper>
        <HeaderWrapper isLeft>
          {subtitle && (
            <H4Text color={colors.white} align="left">
              {subtitle}
            </H4Text>
          )}
          {preTitle}
          <H1 color={colors.white} align="left">
            {title}
          </H1>
          {children && (
            <Blockquote
              color={colors.white}
              align="left"
              style={{ paddingBottom: cta || enterpriseCta ? 0 : 40 }}
            >
              {children}
            </Blockquote>
          )}
          {cta && <EmailForm />}
          {enterpriseCta && (
            <OutlineButton href="mailto:enterprise@cogo.ai" white>
              Schedule a demo
            </OutlineButton>
          )}
        </HeaderWrapper>
        <HeaderWrapper>
          <ArrowDown />
        </HeaderWrapper>
      </ContentWrapper>
      <Overlay
        background={
          isEnterprise
            ? `linear-gradient(45deg, ${colors.green} 0%, ${
                colors.bluegray
              } 95%)`
            : `linear-gradient(45deg, ${colors.green} 15%, ${
                colors.blue
              } 75%, ${colors.purple} 95%, ${colors.red} 115%)`
        }
      />
      {noParticles ? (
        <Overlay
          background={`linear-gradient(45deg, ${colors.green} 15%, ${
            colors.blue
          } 75%, ${colors.purple} 95%, ${colors.red} 115%)`}
        />
      ) : (
        <Particles type={isEnterprise ? 'enterprise' : 'normal'} />
      )}
    </Header>
  );
};
