import React from 'react';
import GatsbyLink from 'gatsby-link';
import styled from 'react-emotion';
import { colors } from '../components/styles';

const InternalLink = styled(GatsbyLink)`
  color: inherit;
  text-decoration: none;
  &:hover {
    color: ${colors.green};
  }
`;
const ExternalLink = InternalLink.withComponent('a');

export default props =>
  props.href ? <ExternalLink {...props} /> : <InternalLink {...props} />;
