export const colors = {
  green: '#47bbb8',
  blue: '#5063ae',
  purple: '#7e5aa6',
  bluegray: '#414662',
  red: '#d42251',
  richRed: '#B12251',
  white: '#fff',
  silver: '#eef3f3',
  mist: '#aaa',
  chrome: '#c2ccd1',
  concrete: '#7e8892',
  gray: '#4b555f',
  ink: '#1f243c',
};
