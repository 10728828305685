import React, { Component } from 'react';
import styled from 'react-emotion';
import Logo from './Logo';
import CappedWidth from '../CappedWidth';
import Menu from './Menu';

const HeaderFull = styled.header`
  height: 100vh;

  @media (min-width: 320px) {
    width: 100%;
    display: table;
  }

  @media (min-width: 1490px) {
    /* height: auto; */
  }
`;

const HeaderHero = styled.header`
  position: relative;
`;

const InnerWrapper = styled.div`
  height: auto;
  background: none;
`;

const ParticlesGradient = styled.div`
  position: relative;
  height: auto;
`;

const Content = styled.div`
  display: table;
  clear: both;
  content: '';
  width: 100%;
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileMenuOpen: false,
    };

    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  toggleMobileMenu() {
    this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen });
  }

  render() {
    const { children, isFull } = this.props;
    const { isMobileMenuOpen } = this.state;

    const BaseComponent = isFull ? HeaderFull : HeaderHero;

    return (
      <BaseComponent>
        <ParticlesGradient>
          <InnerWrapper>
            <CappedWidth>
              <Content backgroundColor="white">
                <Logo />
                <Menu
                  isMobileMenuOpen={isMobileMenuOpen}
                  onToggleMenu={this.toggleMobileMenu}
                />
                {children}
              </Content>
            </CappedWidth>
          </InnerWrapper>
        </ParticlesGradient>
      </BaseComponent>
    );
  }
}
export default Header;
