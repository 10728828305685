import React from 'react';
import styled, { css } from 'react-emotion';
import { colors } from '../components/styles';

const ContainerComponent = styled.div`
  padding-bottom: ${({ padded }) => (padded ? 40 : 0)}px;
  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `};
`;

export const Content = ({ children, ...props }) => (
  <ContainerComponent {...props}>
    <LWrap>{children}</LWrap>
  </ContainerComponent>
);

const Wrap = styled.div`
  max-width: 1140px;
  clear: both;
  margin: 0 auto;
`;

const WrapArticle = styled.div`
  display: table;
  clear: both;
  content: '';
  width: 100%;
`;

export const LWrap = ({ children }) => (
  <Wrap>
    <WrapArticle>{children}</WrapArticle>
  </Wrap>
);

const BgContainerSlantBase = styled.div`
  position: relative;
  padding-top: 100px;
  padding-bottom: ${({ noBottom, isReverse }) =>
    noBottom ? 0 : isReverse ? 160 : 150}px;
  margin-bottom: ${({ noBottom, isReverse }) =>
    noBottom ? 0 : isReverse ? -120 : -100}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  &::before {
    display: block;
    left: 0;
    right: 0;
    top: -60px;
    content: ' ';
    background-color: transparent;
    width: 0px;
    position: absolute;
    border-top-width: 60px;
    border-top-style: solid;
    border-top-color: transparent;
    border-right-width: 100vw;
    border-right-style: ${({ isReverse }) => (isReverse ? 'none' : 'solid')};
    border-left-style: ${({ isReverse }) => (isReverse ? 'solid' : 'none')};
    border-right-color: ${({ backgroundColor }) => backgroundColor};
    border-left-width: 100vw;
    border-left-color: ${({ backgroundColor }) => backgroundColor};
    /* Workaround for IE, as the 100vw overflows the window at 1440px width */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      border-right-width: 98.8vw;
      border-left-width: 98.8vw;
    }
  }
  @media (min-width: 320px) {
    padding-top: 10px;
    margin-top: 30px;
  }
`;

export const SlantedBackground = ({
  backgroundColor = colors.white,
  children,
  isReverse,
  noBottom,
  ...rest
}) => (
  <BgContainerSlantBase
    backgroundColor={backgroundColor}
    isReverse={isReverse}
    noBottom={noBottom}
  >
    {React.Children.map(children, child =>
      React.cloneElement(child, { isReverse, noBottom })
    )}
  </BgContainerSlantBase>
);
