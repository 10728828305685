import React from 'react';
import Helmet from 'react-helmet';
import './Layout.css';

const Layout = ({ children }) => (
  <>
    <Helmet>
      <title>Cogo</title>
      <meta
        name="description"
        content="Cogo allows you to create a smart content library of the most important content in your organization"
      />
      <meta
        name="keywords"
        content="knowledge management, knowledge sharing, smart, content library, content management, sharepoint, confluence"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Work+Sans:300,400,600,700"
        rel="stylesheet"
      />
      <meta property="og:image" content="https://cogo.ai/cover-with-logo.jpg" />
      <link rel="shortcut icon" href="https://cogo.ai/favicon.ico" />
    </Helmet>
    <>{children}</>
  </>
);

export default Layout;
