import React from 'react';
import styled, { css } from 'react-emotion';
import Link from '../components/Link';
import { colors } from '../components/styles';

export const Button = styled.button`
  font-size: 1em;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: ${colors.white};
  background: ${colors.red};
  appearance: none;
  box-shadow: none;
  white-space: nowrap;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  border: 0;
  transition-duration: 0.4s;
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 40)}px;
  margin-top: ${({ noMargin }) => (noMargin ? 0 : 20)}px;
  border: 2px solid ${colors.red};
  text-decoration: none;
  &:hover {
    background-color: ${colors.richRed};
    border: 2px solid ${colors.richRed};
    color: ${colors.white};
  }

  ${props =>
    props.white
      ? css`
          background: none;
          border: 2px solid ${colors.white};
          color: ${colors.white};
        `
      : css`
          background: none;
          border: 2px solid ${colors.red};
          color: ${colors.red};

          &:hover {
            background-color: ${colors.red};
            border: 2px solid ${colors.red};
            color: ${colors.white};
          }
        `};
`;

const OutlineAnchor = Button.withComponent('a');
const OutlineLink = Button.withComponent(Link);

const OutlineButton = props => {
  if (props.to) {
    return <OutlineLink {...props} />;
  }
  if (props.href) {
    return <OutlineAnchor {...props} />;
  }
  return <Button {...props} />;
};

export default OutlineButton;
