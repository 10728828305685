import React from 'react';
import styled from 'react-emotion';
import Link from '../../components/Link';
import cogoWhiteLogoPath from '../../images/cogo_white_logo.svg';

const LogoWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  float: left;

  width: 25%;
  height: 80px;
  display: inline-block;

  @media (min-width: 320px) {
    width: calc((4/12)*100%);
    height: 60px;
  }
  @media (min-width: 500px) {
    width: calc((1/12)*100%);
    height: 60px;
  }
`;

const LogoImage = styled.img`
  height: 80px;
  width: 100px;
  @media (min-width: 320px) {
    height: 60px;
    width: 80px;
  }
  @media (min-width: 690px) {
    height: 80px;
    width: 100px;
  }
`;

const Logo = () => (
  <LogoWrapper>
    <Link to="/">
      <LogoImage src={cogoWhiteLogoPath} alt="Cogo Logo" />
    </Link>
  </LogoWrapper>
);

export default Logo;
