import React from 'react';
import { css } from 'emotion';
import styled from 'react-emotion';
import Particles from 'react-particles-js';
import { colors } from '../components/styles';

export const Overlay = styled.div`
  position: fixed;
  transform: translateZ(0);
  z-index: -1000000;
  top: 0;
  left: 0;
  width: 100%;
  height: inherit;
  min-height: 25em;
  height: 100%;
  background-position: center;
  background: ${({ background }) => background};
`;

const canvasWrapper = css`
  height: 100%;
`;

const enterpriseParticlesConfig = {
  particles: {
    number: {
      value: 10,
      density: {
        enable: false,
        value_area: 750,
      },
    },
    color: {
      value: '#ffffff',
    },
    shape: {
      type: 'circle',
      stroke: {
        width: 0,
        color: colors.green,
      },
      polygon: {
        nb_sides: 5,
      },
    },
    opacity: {
      value: 0.25,
      random: true,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 24,
      random: true,
      anim: {
        enable: false,
        speed: 0.25,
        size_min: 0.1,
        sync: false,
      },
    },
    line_linked: {
      enable: true,
      distance: 500,
      color: '#ffffff',
      opacity: 0.05,
      width: 4,
    },
    move: {
      enable: true,
      speed: 0.25,
      direction: 'none',
      random: true,
      straight: true,
      out_mode: 'out',
      bounce: false,
      attract: {
        enable: true,
        rotateX: 500,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: true,
        mode: 'bubble',
      },
      onclick: {
        enable: false,
        mode: 'push',
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 95.90409590409591,
        size: 63.93606393606394,
        duration: 10,
        opacity: 0.11988011988011989,
        speed: 2,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
  config_demo: {},
};
const defaultParticlesConfig = {
  particles: {
    number: {
      value: 30,
      density: {
        enable: true,
        value_area: 1000,
      },
    },
    color: {
      value: '#ffffff',
    },
    shape: {
      type: 'circle',
      stroke: {
        width: 0,
        color: '#000000',
      },
      polygon: {
        nb_sides: 5,
      },
    },
    opacity: {
      value: 0.1,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 4,
      random: false,
      anim: {
        enable: false,
        speed: 0.25,
        size_min: 0.1,
        sync: false,
      },
    },
    line_linked: {
      enable: true,
      distance: 150,
      color: '#ffffff',
      opacity: 0.05,
      width: 8,
    },
    move: {
      enable: true,
      speed: 0.25,
      direction: 'bottom',
      random: true,
      straight: true,
      out_mode: 'out',
      bounce: true,
      attract: {
        enable: true,
        rotateX: 1200,
        rotateY: 1200,
      },
    },
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: {
        enable: true,
        mode: 'bubble',
      },
      onclick: {
        enable: false,
        mode: 'push',
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1,
        },
      },
      bubble: {
        distance: 95.90409590409591,
        size: 63.93606393606394,
        duration: 10,
        opacity: 0.11988011988011989,
        speed: 2,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
  config_demo: {},
};

export default ({ type }) => {
  let background;
  let params;
  switch (type) {
    case 'enterprise':
      background = `linear-gradient(45deg, ${colors.green} 0%, ${
        colors.bluegray
      } 95%)`;
      params = enterpriseParticlesConfig;
      break;
    default:
      background = `linear-gradient(45deg, ${colors.green} 15%, ${
        colors.blue
      } 75%, ${colors.purple} 95%, ${colors.red} 115%)`;
      params = defaultParticlesConfig;
      break;
  }

  return (
    <Overlay background={background}>
      <Particles className={canvasWrapper} params={params} />
    </Overlay>
  );
};
