import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import OutlineButton from '../../components/OutlineButton';
import Link from '../../components/Link';
import cogoCancelWhite from '../../images/cogo_cancel_white.svg';
import cogoMenuPath from '../../images/cogo_menu.svg';
import { colors } from '../../components/styles';

const Wrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  height: 80px;
  width: 75%;
  display: inline-block;
  text-align: right;
  @media (min-width: 320px) {
    width: calc((8/12)*100%);
    height: 60px;
  }
  @media (min-width: 500px) {
    width: calc((11/12)*100%);
  }
  @media (min-width: 690px) {
    width: calc((10/12)*100%);
    height: 80px;
  }
  @media (min-width: 810px) {
    width: calc((10/12)*100%);
    float: right;
  }
  @media screen and (max-width: 810px) and (orientation: portrait) {
    text-align: center;
    margin: 0 auto;
  }
  @media screen and (max-width: 810px) and (orientation: landscape) {
    text-align: center;
    margin: 0 auto;
  }
`;

const MenuToggle = styled.div`
  text-decoration: none;
  color: ${colors.white};
  text-align: center;
  display: none;
  cursor: pointer;
  top: 10px;
  right: 10px;
  position: absolute;
  @media screen and (max-width: 810px) {
    display: block;
    width: 36px;
    top: 20px;
    right: 20px;
    position: absolute;
  }
  @media screen and (max-width: 689px) {
    display: block;
    width: 36px;
    top: 10px;
    right: 20px;
    position: absolute;
  }
`;

const ListItem = styled.li`
  display: inline-block;
  @media (min-width: 690px) {
    padding-left: 25px;
  }
  @media (min-width: 810px) {
    padding-left: 20px;
  }
  @media (min-width: 970px) {
    padding-left: 30px;
  }
  @media (min-width: 1140px) {
    padding-left: 40px;
  }
  @media screen and (max-width: 810px) and (orientation: portrait) {
    margin: 0;
    left: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0px;
    height: 10vh;
  }
  @media screen and (max-width: 810px) and (orientation: landscape) {
    margin: 0;
    left: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
    height: 10vh;
  }
  @media (max-width: 810px) {
    width: 100%;
  }
`;

const MenuLink = styled(Link)`
  color: ${colors.white};
  text-decoration: none;
  font-size: 1em;
  &:hover {
    border-bottom: 2px solid ${colors.green};
    color: ${colors.green};
    padding-bottom: 10px;
  }
  @media screen and (max-width: 810px) and (orientation: portrait) {
    font-size: 1.25em;
    font-weight: 300;
    color: ${colors.white};
    text-decoration: none;
    padding: 0;
    margin: 0;
    &:hover {
      border-bottom: 2px solid ${colors.green};
      color: ${colors.green};
      padding-bottom: 10px;
    }
  }
  @media screen and (max-width: 810px) and (orientation: landscape) {
    font-size: 1em;
    font-weight: 300;
    color: ${colors.white};
    text-decoration: none;
    padding: 0;
    margin: 0;
    &:hover {
      border-bottom: 2px solid ${colors.green};
      color: ${colors.green};
      padding-bottom: 10px;
    }
  }
`;

const MenuList = styled.ul`
  @media screen and (max-width: 810px) {
    display: none;
  }
  ${props =>
    props.open &&
    css`
      display: block !important;
      @media screen and (max-width: 810px) {
        display: block;
        background: ${colors.ink};
        width: 100%;
        height: 100vh;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        padding-top: 10vh;
        padding-bottom: 0px;
        margin-top: 0px;
        z-index: 10000000;
      }
    `};
`;

const MenuItem = ({ outline, ...rest }) => (
  <ListItem>
    {outline ? (
      <OutlineButton white noMargin {...rest} />
    ) : (
      <MenuLink {...rest} />
    )}
  </ListItem>
);

const ResponsiveMenuItem = styled(MenuItem)`
  @media (min-width: 810px) {
    display: none;
  }
`;

const OpenMenu = ({ onToggleMenu }) => (
  <MenuToggle onClick={onToggleMenu}>
    <img src={cogoMenuPath} alt="Cogo Menu Icon" />
  </MenuToggle>
);

const CloseMenu = ({ onToggleMenu }) => (
  <MenuToggle onClick={onToggleMenu}>
    <img src={cogoCancelWhite} alt="Cogo Cancel Icon" />
  </MenuToggle>
);

const Menu = ({ isMobileMenuOpen, onToggleMenu }) => (
  <Wrapper>
    {!isMobileMenuOpen && <OpenMenu onToggleMenu={onToggleMenu} />}
    <MenuList open={isMobileMenuOpen}>
      <CloseMenu onToggleMenu={onToggleMenu} />
      <ResponsiveMenuItem to="/">Home</ResponsiveMenuItem>
      <MenuItem href="https://app.cogo.ai/">Log in</MenuItem>
      <MenuItem to="/partners/">Partners</MenuItem>
      <MenuItem href="mailto:info@cogo.ai" outline>
        Schedule a demo
      </MenuItem>
    </MenuList>
  </Wrapper>
);

export default Menu;
