import styled from 'react-emotion';

const Item = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: ${({ padded }) => (padded ? 40 : 0)}px;
  @media (min-width: 690px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: 810px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const GridTopCol = styled(Item)`
  width: 100%;
  float: left;
  margin: 0 auto;
  text-align: center;
  @media (min-width: 320px) {
    width: 100%;
    margin-bottom: 0;
    padding-top: 20px;
  }
  @media (min-width: 500px) {
    width: calc((10/12)*100%);
    margin-left: calc((1/12)*100%);
  }
`;

export const GridLeftTopCol = styled(Item)`
  width: 100%;
  float: left;
  margin: 0 auto;
  text-align: center;
  @media (min-width: 320px) {
    margin-bottom: 0;
    padding-top: 20px;
  }
  @media (min-width: 500px) {
    width: calc((10/12)*100%);
  }
  @media (min-width: 810px) {
    width: calc((8/12)*100%);
    margin-right: calc((4/12)*100%);
  }
  @media (min-width: 1140px) {
    width: calc((9/12)*100%);
    margin-right: calc((3/12)*100%);
  }
`;

export default Item;
