import styled from 'react-emotion';
import { colors } from '../components/styles';
import React from 'react';
import Helmet from 'react-helmet';

const resolveColor = ({ color = colors.gray }) => color;
const resolveHeadingAlignment = ({ align = 'inherit' }) => align;
const resolveTextAlignment = ({ align = 'inherit' }) => align;

export const H1Component = styled.h1`
  color: ${resolveColor};
  text-align: ${resolveHeadingAlignment};
  font-size: 2em;
  font-weight: 700;
  line-height: 125%;
  @media (min-width: 500px) {
    font-size: 2em;
  }
  @media (min-width: 690px) {
    font-size: 2.25em;
  }
  @media (min-width: 810px) {
    font-size: 2.5em;
  }
  @media (min-width: 970px) {
    font-size: 2.75em;
  }
  @media (min-width: 1140px) {
    font-size: 3em;
  }
`;

export const H1 = props => [
  <H1Component {...props} key="title" />,
  <Helmet title={`Cogo - ${props.children}`} key="helmet" />,
];

export const H2 = styled('h2')`
  color: ${resolveColor};
  text-align: ${resolveHeadingAlignment};
  font-size: 1.75em;
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: 125%;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  @media (min-width: 500px) {
    font-size: 1.75em;
  }
  @media (min-width: 690px) {
    font-size: 2em;
  }
  @media (min-width: 810px) {
    font-size: 2.25em;
  }
  @media (min-width: 970px) {
    font-size: 2em;
  }
  @media (min-width: 1140px) {
    font-size: 2.5em;
  }
`;

export const H3 = styled('h3')`
  color: ${resolveColor};
  text-align: ${resolveHeadingAlignment};
  font-size: 1.1em;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 135%;
  margin-top: 1em;
  margin-bottom: 1em;
  @media (min-width: 500px) {
    font-size: 1.25em;
  }
  @media (min-width: 1140px) {
    font-size: 1.5em;
  }
`;

export const H4 = styled('h4')`
  color: ${resolveColor};
  text-align: ${resolveHeadingAlignment};
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 0.125em;
  line-height: 135%;
  margin-top: 1.33em;
  margin-bottom: 1.33em;

  @media (min-width: 500px) {
    letter-spacing: 0.15em;
  }
  @media (min-width: 810px) {
    letter-spacing: 0.175em;
  }
`;

export const H5 = styled('h5')`
  color: ${resolveColor};
  text-align: ${resolveHeadingAlignment};
  font-size: 1em;
  font-weight: 600;
  line-height: 135%;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
`;

export const H6 = styled('h6')`
  color: ${resolveColor};
  text-align: ${resolveHeadingAlignment};
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 135%;
  margin-top: 2.33em;
  /* margin-bottom: 2.33em; this is default */
  margin-bottom: 0;
  @media (min-width: 500px) {
    font-size: 0.8em;
  }
  @media (min-width: 970px) {
    font-size: 1em;
  }
`;

export const H6Text = H6.withComponent('div');
export const H5Text = H5.withComponent('div');
export const H4Text = H4.withComponent('div');
export const H3Text = H3.withComponent('div');
export const H2Text = H2.withComponent('div');

export const Blockquote = styled.blockquote`
  color: ${resolveColor};
  text-align: ${resolveTextAlignment};
  font-size: 1.1em;
  margin-left: 0;
  margin-right: 0;
  line-height: 145%;
  @media (min-width: 500px) {
    font-size: 1.25em;
  }
  @media (min-width: 1140px) {
    font-size: 1.5em;
  }
`;

export const Paragraph = styled.p`
  color: ${resolveColor};
  text-align: ${resolveTextAlignment};
  font-size: 0.9em;
  font-weight: 400;
  line-height: 145%;
`;

export const Small = styled.small`
  color: ${resolveColor};
  text-align: ${resolveTextAlignment};
`;

export const Strong = styled.strong`
  font-weight: 600;
  letter-spacing: 0.02em;
`;

export const Highlight = styled.span`
  background: ${colors.silver};
  padding: 3px;
  color: ${colors.gray};
  font-weight: 600;
`;
